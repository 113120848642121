<template class="my-kiosk">
  <v-sheet dark v-if="event">
    <logo class="hidden-lg-and-up" :logo="event.logoMobile" />

    <v-container class="px-0 py-0" fluid>
      <v-row class="kiosk" v-if="exhibitorSelf">
        <v-col class="content" cols="12" lg="8" offset="2">
          <div class="d-flex align-center justify-space-between my-2">
            <h2>{{ $t('exhibitor.myKiosk') }}</h2>

            <v-btn class="mx-2" color="primary" x-large :to="kioskConfigurationRoute">
              <h3 class="mr-5">{{ $t('exhibitor.modify') }}</h3>
              <v-icon>mdi-tools</v-icon>
            </v-btn>

            <v-switch
              v-model="status"
              color="primary"
              inset
              true-value="online"
              false-value="offline"
              :label="
                status === 'online' ? $t('exhibitor.status.online') : $t('exhibitor.status.offline')
              "
              :loading="exhibitorIsSaving"
              @change="change"
            />
          </div>

          <div class="room">
            <iframe
              style="width: 100%; border: none"
              class="room__iframe"
              :src="getWherebyUrl()"
              allow="camera; microphone; fullscreen; speaker; display-capture"
            ></iframe>
          </div>
        </v-col>

        <v-col cols="12" lg="2">
          <v-btn
            color="primary"
            outlined
            class="flex justify-center align-content-center"
            @click="toggleNav"
          >
            <v-icon class="mx-2">mdi-account-group</v-icon>
            {{
              $tc('exhibitor.connectedUsers', exhibitorVisitors.length, {
                count: exhibitorVisitors.length,
              })
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-navigation-drawer
      class="my-kiosk__menu"
      v-model="isNavOpened"
      height="100%"
      width="25%"
      app
      right
    >
      <v-icon color="primary" class="drawer-close" @click="toggleNav()"> mdi-close</v-icon>
      <div class="ma-5">
        <div>
          <h2>
            {{
              $tc('exhibitor.connectedUsers', exhibitorVisitors.length, {
                count: exhibitorVisitors.length,
              })
            }}
          </h2>
        </div>

        <relation
          class="participant"
          v-for="exhibitorVisitor in exhibitorVisitors"
          :key="exhibitorVisitor.user.id"
          :relation="exhibitorVisitor.user"
          :is-contact="isContact(exhibitorVisitor.user)"
          :show-calendar="getUserHasAppointment(exhibitorVisitor.user.id)"
          :logged-user-id="loggedInUser.id"
          @add-connection="addConnection"
          @remove-connection="removeConnection"
          @go-to-profile="goToProfile"
          @chat="openChat"
          @booking="booking"
        />
      </div>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  APP_EXHIBITOR_MODULE,
  CONNECT_TO_EXHIBITOR_USER_SOCKET,
  DISCONNECT_FROM_EXHIBITOR_USER_SOCKET,
  EXHIBITOR_PUNCH_IN,
  EXHIBITOR_PUNCH_OUT,
  GET_SELF_EXHIBITOR_USER,
} from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { GET_EXHIBITOR } from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import {
  APP_EXHIBITOR_VISITOR_MODULE,
  SEARCH_EXHIBITOR_VISITORS,
} from '@/stores/umanize-app/actions/exhibitor-visitor/app-exhibitor-visitor.actions';
import {
  ADD_TO_CONTACTS,
  GET_RELATIONS,
  RELATIONS_MODULE,
  REMOVE_FROM_CONTACTS,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import {
  APPOINTMENT_MODULE,
  CHECK_USERS_HAVE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';

import Relation from '@/components/relation/Relation.vue';
import Logo from '@/components/logo/Logo.vue';

import { whereByIframeSrcBuilder } from '@/helpers/whereby/whereby.helper';

import { toUserBooking } from '@/navigation';

export default {
  name: 'MyKiosk',
  components: {
    Logo,
    Relation,
  },
  data() {
    return {
      status: 'online',
      eventId: null,
      isNavOpened: true,
    };
  },
  computed: {
    ...mapGetters(RELATIONS_MODULE, ['isConnected']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APPOINTMENT_MODULE, ['getUserHasAppointment']),
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitorSelf', 'exhibitor', 'exhibitorIsSaving']),
    ...mapGetters(APP_EXHIBITOR_VISITOR_MODULE, ['exhibitorVisitors']),

    kioskConfigurationRoute() {
      const { eventId } = this.$route.params;

      return `/events/${eventId}/my-kiosk/configure`;
    },
  },
  methods: {
    ...mapActions(APP_EXHIBITOR_MODULE, [
      GET_SELF_EXHIBITOR_USER,
      CONNECT_TO_EXHIBITOR_USER_SOCKET,
      DISCONNECT_FROM_EXHIBITOR_USER_SOCKET,
      EXHIBITOR_PUNCH_IN,
      EXHIBITOR_PUNCH_OUT,
      GET_EXHIBITOR,
    ]),
    ...mapActions(APP_EXHIBITOR_VISITOR_MODULE, [SEARCH_EXHIBITOR_VISITORS]),
    ...mapActions(RELATIONS_MODULE, [GET_RELATIONS, ADD_TO_CONTACTS, REMOVE_FROM_CONTACTS]),
    ...mapActions(APPOINTMENT_MODULE, [CHECK_USERS_HAVE_APPOINTMENT]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    async initialize() {
      this.status = this.exhibitorSelf?.status || this.status;
      const { eventId } = this.$route.params;
      this.eventId = eventId;

      await this[GET_SELF_EXHIBITOR_USER]({ eventId });
      await this[GET_EXHIBITOR]({
        eventId,
        exhibitorId: this.exhibitorSelf?.exhibitorId,
      });

      this[CONNECT_TO_EXHIBITOR_USER_SOCKET]({
        eventId,
        exhibitorId: this.exhibitorSelf?.exhibitorId,
        userId: this.loggedInUser.id,
      });

      await this[SEARCH_EXHIBITOR_VISITORS]({
        eventId,
        exhibitorId: this.exhibitorSelf?.exhibitorId,
      });
    },
    getWherebyUrl() {
      return whereByIframeSrcBuilder(this.exhibitorSelf?.wherebyHostUrl, {
        background: 'off',
        chat: 'on',
        people: 'on',
        screenShare: 'on',
        recording: 'on',
        displayName: `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`,
      });
    },
    change(event) {
      const { eventId } = this.$route.params;
      const action = event === 'online' ? EXHIBITOR_PUNCH_IN : EXHIBITOR_PUNCH_OUT;

      this[action]({
        eventId,
        exhibitorId: this.exhibitor.id,
        userId: this.loggedInUser.id,
      });
    },
    toggleNav() {
      this.isNavOpened = !this.isNavOpened;
    },
    isContact(relation) {
      return this.isConnected(relation.id);
    },
    addConnection(relation) {
      this[ADD_TO_CONTACTS](relation);
    },
    removeConnection(relation) {
      this[REMOVE_FROM_CONTACTS](relation);
    },
    openChat(relation) {
      this[OPEN_CHAT]({ userIds: [relation.id] });
    },
    goToProfile(userId) {
      this.$router.push({ name: 'EventPublicProfile', params: { userId, eventId: this.event.id } });
    },
    booking(relation) {
      toUserBooking(this.$router)({
        eventId: this.event.id,
        userId: relation.id,
      });
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    exhibitorSelf(value) {
      this.status = value?.status;
    },
  },
  beforeDestroy() {
    this[DISCONNECT_FROM_EXHIBITOR_USER_SOCKET]({
      eventId: this.eventId,
      exhibitorId: this.exhibitorSelf?.exhibitorId,
      userId: this.loggedInUser.id,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.my-kiosk__menu {
  margin: $header-mobile-height auto auto auto;
  @include breakpoint(medium) {
    margin: $header-height auto auto auto;
  }
}

.kiosk {
  min-height: calc(100vh - 6rem);
}

.content {
  display: flex;
  flex-direction: column;

  min-height: 80vh;
}

.room {
  display: flex;
  justify-content: center;
  min-height: 80%;

  &__iframe {
    width: 90%;
  }
}

@include breakpoint(small) {
  .room {
    &__iframe {
      width: 80%;
    }
  }
}

@include breakpoint(medium) {
  .room {
    &__iframe {
      width: 70%;
    }
  }
}
</style>
